function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
var id = "reach-skip-nav"; ////////////////////////////////////////////////////////////////////////////////
// SkipNavLink

export function SkipNavLink(_ref) {
  var _ref$children = _ref.children,
      children = _ref$children === void 0 ? "Skip to content" : _ref$children,
      props = _objectWithoutPropertiesLoose(_ref, ["children"]);

  return React.createElement("a", _extends({}, props, {
    href: "#" + id,
    "data-reach-skip-link": true
  }), children);
}
SkipNavLink.displayName = "SkipNavLink"; ////////////////////////////////////////////////////////////////////////////////
// SkipNavContent

export var SkipNavContent = function SkipNavContent(props) {
  return React.createElement("div", _extends({}, props, {
    id: id
  }));
};
SkipNavContent.displayName = "SkipNavContent";