import React from "react";

const initialContext = {
    navCollapsed: undefined,
    navMinimized: undefined,
    navStateOverridden: false,
    onCollapsed: (_) => _,
    onMinimized: (_) => _,
    onNavStateOverride: (_) => _,
};

const layoutContext = React.createContext(initialContext);

export { layoutContext };
