import React, { useState, useEffect, useRef, useContext } from "react";
import { layoutContext } from "@context/layout";
import { throttle } from "@modules/helpers";

export function useMedia(queries, values, defaultValue) {
    // Array containing a media query list for each query
    const mediaQueryLists = queries.map((q) =>
        typeof window !== "undefined" ? window.matchMedia(q) : { matches: undefined }
    );

    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.findIndex((mql) => mql.matches);
        // Return related value or defaultValue if none
        return typeof values[index] !== "undefined" ? values[index] : defaultValue;
    };

    // State and setter for matched value
    const [value, setValue] = useState(getValue);

    useEffect(
        () => {
            // Event listener callback
            // Note: By defining getValue outside of useEffect we ensure that it has ...
            // ... current values of hook args (as this hook callback is created once on mount).
            const handler = () => setValue(getValue);
            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach((mql) => mql.addListener(handler));
            // Remove listeners on cleanup
            return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
        },
        [] // Empty array ensures effect is only run on mount and unmount
    );

    return value;
}

const MOBILE_UPPER_THRESHOLD = 576;
const TABLET_LOWER_THRESHOLD = MOBILE_UPPER_THRESHOLD + 1;
const TABLET_UPPER_THRESHOLD = 1199;
const DESKTOP_LOWER_THRESHOLD = TABLET_UPPER_THRESHOLD + 1;

export function useScreenCategory() {
    const screenSizeCategory = useMedia(
        // Media queries
        [`(min-width: ${DESKTOP_LOWER_THRESHOLD}px)`, `(min-width: ${TABLET_LOWER_THRESHOLD}px)`],
        // Column counts (relates to above media queries by array index)
        ["large", "medium"],
        // Default column count
        "small"
    );

    const isLarge = screenSizeCategory === "large";
    const isMedium = screenSizeCategory === "medium";
    const isSmall = screenSizeCategory === "small";

    return [isSmall, isMedium, isLarge];
}

/**
 * Returns whether or not a component should be pinned depending on the scroll position
 */
export function useScrollBasedPin(initialState, options) {
    const [isPinned, setIsPinned] = useState(initialState);
    const scrollRecord = useRef(0);

    const { pinThreshold = 0, downScrollTolerance = 2, upScrollTolerance = 5 } = options || {};

    useEffect(() => {
        const getDocumentHeight = () => {
            const { body } = document;

            return Math.max(body.scrollHeight, body.offsetHeight, body.clientHeight);
        };

        const isOutOfBounds = (scrollY) => {
            const viewportHeight = window.innerHeight;
            const documentHeight = getDocumentHeight();

            const pastTop = scrollY < 0;
            const pastBottom = scrollY + viewportHeight > documentHeight;

            return pastTop || pastBottom;
        };

        const getPinState = (scrollY) => {
            const scrollDirection = scrollY >= scrollRecord.current ? "down" : "up";
            const distanceScrolled = Math.abs(scrollY - scrollRecord.current);
            const scrollPosPastThreshold = scrollY > pinThreshold;

            if (
                scrollDirection === "down" &&
                scrollPosPastThreshold &&
                distanceScrolled > downScrollTolerance
            ) {
                return "unpin";
            } else if (
                (scrollDirection === "up" && distanceScrolled > upScrollTolerance) ||
                !scrollPosPastThreshold
            ) {
                return "pin";
            } else {
                return "constant";
            }
        };

        const listener = () => {
            var scrollY = window.pageYOffset;

            if (!isOutOfBounds(scrollY)) {
                const action = getPinState(scrollY);

                if (action === "pin" && !isPinned) setIsPinned(true);
                if (action === "unpin" && isPinned) setIsPinned(false);
            }
            scrollRecord.current = scrollY <= 0 ? 0 : scrollY; // For Mobile or negative scrolling
        };

        document.addEventListener("scroll", listener);

        return () => {
            document.removeEventListener("scroll", listener);
        };
    }, [isPinned]);

    return isPinned;
}

/**
 * This should only be used for components that are not in the document flow AND aren't
 * displayed initially. All elements in flow that are affected by the navbar should be
 * placed inside the content element of the layout component (which is already layout
 * aware for you). All elements that are SSR need to have CSS to handle their initial
 * styling as no effects will be run on the server.
 */
export function useFixedLayoutOffset(conditional) {
    const elementToModify = useRef();
    const { navCollapsed } = useContext(layoutContext);
    const [isSmall] = useScreenCategory();

    useEffect(() => {
        setTimeout(() => {
            if (!elementToModify.current) return;
            else {
                if (isSmall) elementToModify.current.style.setProperty("--nav-offset", "0");
                if (!isSmall && navCollapsed)
                    elementToModify.current.style.setProperty("--nav-offset", "80px");
                if (!isSmall && !navCollapsed)
                    elementToModify.current.style.setProperty("--nav-offset", "220px");
            }
        }, 0);
    }, [conditional, navCollapsed, isSmall]);

    return elementToModify;
}

export function useScroll(dep) {
    const [scrollPos, setScrollPos] = useState();

    useEffect(() => {
        function handleScroll() {
            // We don't want to update state if scrollY is 0 because scroll restoration breaks.
            if (!dep && window.scrollY !== 0) {
                setScrollPos(window.scrollY);
            }
        }

        const throttledScroll = throttle(handleScroll, 100);

        window.addEventListener("scroll", throttledScroll);
        return () => window.removeEventListener("scroll", throttledScroll);
    }, [dep]);

    // restore scroll whenever loading changes
    useEffect(() => {
        window.scrollTo(0, scrollPos);
    }, [dep]);
}

export function useScrollTopThreshold(threshold = 0) {
    const [isPastThreshold, setIsPastThreshold] = useState(true);

    function updateNav() {
        const scrollY = window.pageYOffset;

        if (scrollY >= threshold && isPastThreshold) setIsPastThreshold(false);
        else if (scrollY < threshold) setIsPastThreshold(true);
    }

    useEffect(() => {
        document.addEventListener("scroll", updateNav);

        return () => {
            document.removeEventListener("scroll", updateNav);
        };
    }, [isPastThreshold]);

    return isPastThreshold;
}

export function useScrollPercentage(percentage, callback, once) {
    useEffect(() => {
        function checkPercentage() {
            const h = document.documentElement,
                b = document.body,
                st = "scrollTop",
                sh = "scrollHeight";

            const percent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;

            if (percentage < percent && callback) {
                callback();
                if (once) document.removeEventListener("scroll", checkPercentage);
            }
        }

        document.addEventListener("scroll", checkPercentage);
        return () => document.removeEventListener("scroll", checkPercentage);
    }, []);
}
